
  import { Component, Prop } from 'vue-property-decorator'
  import { Field } from './field'
  import { mapRules } from '@/components/forms'
  import { VRow } from 'vuetify/lib/components'

@Component({
  components: {
    VRow,
    Record: () => import('./linked/Record.vue'),
    Card: () => import('./linked/Card.vue'),
    Selector: () => import('./linked/Selector.vue'),
  },
})
  export default class LinkedForm extends Field {
  @Prop({ type: String, required: true }) model!: string
  @Prop({ type: String, required: true }) slug!: string
  @Prop({ type: String }) nested?: string

  @Prop({ type: Boolean, default: true }) preventRemote: boolean

  @Prop({ type: String, default: 'record' }) readonly type!: string;
  @Prop({ type: Object }) readonly properties!: any;
  @Prop({ type: Array, default: () => [] }) readonly items!: Array<any>;

  get success () {
    const { value } = this
    if (value && 'serialized' in value) return JSON.stringify({ data: value.serialized })

    return ''
  }

  get hiddenInput () {
    const { preventRemote } = this
    if (!preventRemote) return undefined

    const { success } = this
    if (!success) return undefined

    const { name } = this
    return {
      component: 'input',
      data: {
        attrs: {
          name,
          type: 'hidden',
          value: success,
        },
      },
    }
  }

  get child () {
    const { type } = this
    if (!type || type === 'hidden') return undefined

    const { properties, rules, items, disabled, required, value } = this
    return {
      component: type,
      data: {
        ref: 'link',
        props: {
          value,
          properties: {
            ...properties,
            disabled,
            required,
            rules: mapRules(rules),
            items,
          },
        },
        on: {
         input: e => this.$emit('input', e),
         'update:search-input': e => this.$emit('update:search-input', e),
         'nested:form': () => this.$emit('nested:form', this.name),
        },
      },
    }
  }

  render (h) {
    const { child, hiddenInput } = this
    if (!child && !hiddenInput) return

    const children = [child, hiddenInput].filter(child => child)
    const activeChildren = children.map(({ component, data }) => h(component, data))

    return h(
      'v-row',
      {
        class: { 'pa-0': true, 'ma-0': true },
        props: { noGutters: true },
      },
      activeChildren
    )
  }
  }
